


function spaceConcat(x1, x2) {
  if (x1 === "") {
    return x2;
  } else if (x2 === "") {
    return x1;
  } else {
    return x1 + (" " + x2);
  }
}

var $plus$plus$plus = spaceConcat;

export {
  spaceConcat ,
  $plus$plus$plus ,
  
}
/* No side effect */
