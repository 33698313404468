

import * as Cn from "../Cn.bs.js";
import * as Row from "../../toolkit/Row.bs.js";
import * as $$Text from "../../toolkit/Text.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "../../toolkit/Prelude.bs.js";
import * as Checkbox_Css from "./Checkbox_Css.bs.js";

function Checkbox(Props) {
  var labelText = Props.labelText;
  var id = Props.id;
  var name = Props.name;
  var checked = Props.checked;
  var onChange = Props.onChange;
  var onClick = function ($$event) {
    $$event.stopPropagation();
    $$event.preventDefault();
    return Curry._1(onChange, undefined);
  };
  return React.createElement("div", undefined, React.createElement("label", {
                  className: Checkbox_Css.checkbox,
                  htmlFor: id,
                  onClick: onClick
                }, React.createElement(Row.make, {
                      gap: /* Sm */0,
                      children: null
                    }, React.createElement("span", {
                          className: Prelude.$plus$plus$plus(Checkbox_Css.checkmark, Cn.ifTrue(Checkbox_Css.checkmarkChecked, checked))
                        }), React.createElement($$Text.P.make, {
                          children: labelText
                        }), React.createElement("input", {
                          className: Checkbox_Css.checkboxInput,
                          checked: checked,
                          name: name,
                          type: "checkbox",
                          onChange: (function (_event) {
                              return Curry._1(onChange, undefined);
                            })
                        }))));
}

var Css;

var make = Checkbox;

export {
  Css ,
  make ,
  
}
/* Row Not a pure module */
