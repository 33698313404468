

import * as Card from "../toolkit/Card.bs.js";
import * as Head from "../components/head/Head.bs.js";
import * as I18n from "../i18n/I18n.bs.js";
import * as $$Text from "../toolkit/Text.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Stack from "../toolkit/Stack.bs.js";
import * as React from "react";
import * as Button from "../toolkit/Button.bs.js";
import * as Layout from "../layout/Layout.bs.js";
import * as Checkbox from "../components/checkbox/Checkbox.bs.js";
import * as Constants from "../Constants.bs.js";
import * as MuiSelect from "../components/mui/MuiSelect.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Name$BsFaker from "bs-faker/src/Name.bs.js";
import * as Locale$BsFaker from "bs-faker/src/Locale.bs.js";
import * as LimitedWidthContainer from "../toolkit/LimitedWidthContainer.bs.js";

function localeToFakerLocale(locale) {
  switch (locale) {
    case "en" :
        return /* En */5;
    case "ru" :
        return /* Ru */29;
    default:
      return /* En */5;
  }
}

function genNames(showFirstName, showLastName, locale) {
  var fakerLocale = localeToFakerLocale(locale);
  Locale$BsFaker.setLocale(fakerLocale);
  return Belt_Array.map(new Array(10), (function (param) {
                var firstName = Name$BsFaker.firstName(undefined, undefined);
                var lastName = Name$BsFaker.lastName(undefined, undefined);
                if (showFirstName) {
                  if (showLastName) {
                    return firstName + (" " + lastName);
                  } else {
                    return firstName;
                  }
                } else if (showLastName) {
                  return lastName;
                } else {
                  return "-";
                }
              }));
}

function languageVariants(param) {
  return Belt_Array.map(Constants.availableLocalesJs, (function (locale) {
                return {
                        id: locale,
                        label: I18n.t("locales." + locale)
                      };
              }));
}

function NamesPage$Generator(Props) {
  var locale = Props.locale;
  var labelFirstName = I18n.t("names.body.label_first_name");
  var labelLastName = I18n.t("names.body.label_last_name");
  var generateButtonText = I18n.t("names.body.generate_button_text");
  var result = I18n.t("names.body.result");
  var selectLanguage = I18n.t("names.body.select_language");
  var match = React.useState((function () {
          
        }));
  var setNames = match[1];
  var names = match[0];
  var match$1 = React.useState((function () {
          return true;
        }));
  var toggleFirstName = match$1[1];
  var showFirstName = match$1[0];
  var toggleFirstName$1 = function (_event) {
    return Curry._1(toggleFirstName, (function (param) {
                  return !showFirstName;
                }));
  };
  var match$2 = React.useState((function () {
          return true;
        }));
  var toggleLastName = match$2[1];
  var showLastName = match$2[0];
  var toggleLastName$1 = function (_event) {
    return Curry._1(toggleLastName, (function (param) {
                  return !showLastName;
                }));
  };
  var match$3 = React.useState((function () {
          return locale;
        }));
  var changeLanguage = match$3[1];
  var language = match$3[0];
  var changeLanguage$1 = function (language) {
    return Curry._1(changeLanguage, (function (param) {
                  return language;
                }));
  };
  var refreshNames = function (param) {
    return Curry._1(setNames, (function (param) {
                  return genNames(showFirstName, showLastName, language);
                }));
  };
  var onClickRandomize = function ($$event) {
    $$event.preventDefault();
    return refreshNames(undefined);
  };
  React.useEffect((function () {
          refreshNames(undefined);
          
        }), ([]));
  React.useEffect((function () {
          refreshNames(undefined);
          
        }), /* tuple */[
        showFirstName,
        showLastName,
        language
      ]);
  return React.createElement(LimitedWidthContainer.make, {
              maxWidth: /* Px840 */2,
              fullWidthMobile: true,
              children: React.createElement(Stack.make, {
                    gap: /* Lg */3,
                    children: null
                  }, React.createElement(Card.make, {
                        children: null
                      }, React.createElement("div", undefined, React.createElement(Checkbox.make, {
                                labelText: labelFirstName,
                                id: "first name checkbox",
                                name: "first name checkbox",
                                checked: showFirstName,
                                onChange: toggleFirstName$1
                              }), React.createElement(Checkbox.make, {
                                labelText: labelLastName,
                                id: "last name checkbox",
                                name: "last name checkbox",
                                checked: showLastName,
                                onChange: toggleLastName$1
                              })), React.createElement(MuiSelect.make, {
                            labelId: "language label",
                            selectId: "select label",
                            label: selectLanguage,
                            items: languageVariants(undefined),
                            selectedItemId: language,
                            onSelect: changeLanguage$1
                          })), React.createElement(Button.make, {
                        label: generateButtonText,
                        onClick: onClickRandomize
                      }), React.createElement(Card.make, {
                        children: null
                      }, React.createElement("h5", undefined, result), names !== undefined ? React.createElement("ul", undefined, Belt_Array.mapWithIndex(names, (function (i, name) {
                                    return React.createElement("li", {
                                                key: String(i)
                                              }, name);
                                  }))) : null))
            });
}

var Generator = {
  Faker: undefined,
  localeToFakerLocale: localeToFakerLocale,
  genNames: genNames,
  languageVariants: languageVariants,
  make: NamesPage$Generator
};

function NamesPage(Props) {
  var pageContext = Props.pageContext;
  var locale = Belt_Option.getWithDefault(pageContext.locale, "en");
  I18n.changeLanguage(locale);
  var title = I18n.t("names.head.title");
  var description = I18n.t("names.head.description");
  var h1Text = I18n.t("names.body.h1");
  var h2Text = I18n.t("names.body.h2");
  return React.createElement(React.Fragment, undefined, React.createElement(Head.make, {
                  title: title,
                  description: description,
                  lang: locale,
                  route: /* Names */1
                }), React.createElement(Layout.make, {
                  route: /* Names */1,
                  locale: locale,
                  children: null
                }, React.createElement($$Text.H1.make, {
                      align: /* center */98248149,
                      children: h1Text
                    }), React.createElement($$Text.H2.make, {
                      align: /* center */98248149,
                      children: h2Text
                    }), React.createElement(NamesPage$Generator, {
                      locale: locale
                    })));
}

var route = /* Names */1;

var make = NamesPage;

var $$default = NamesPage;

export {
  route ,
  Generator ,
  make ,
  $$default ,
  $$default as default,
  
}
/* Card Not a pure module */
