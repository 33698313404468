

import * as SelectJs from "../../../components/select.js";

var make = SelectJs.default;

var item1 = {
  id: "1",
  label: "111111"
};

var item2 = {
  id: "2",
  label: "222222"
};

var values = [
  item1,
  item2
];

export {
  make ,
  item1 ,
  item2 ,
  values ,
  
}
/* make Not a pure module */
