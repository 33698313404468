import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const selectValues = [
  {
    id: "en",
    label: "English",
  },
  {
    id: "ru",
    label: "Russian",
  },
]

export default function SimpleSelect(props) {
  const {
    items,
    selectedItemId = "",
    label,
    labelId,
    selectId,
    onSelect,
  } = props

  const classes = useStyles()

  const [currentId, setCurrentId] = React.useState(selectedItemId)

  const handleChange = event => {
    const targetValue = event.target.value
    onSelect(targetValue)
    setCurrentId(targetValue)
  }

  const variants = items.map(v => {
    return (
      <MenuItem key={v.id} value={v.id}>
        {v.label}
      </MenuItem>
    )
  })

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        id={selectId}
        value={currentId}
        onChange={handleChange}
      >
        {variants}
      </Select>
    </FormControl>
  )
}
