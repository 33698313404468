

import * as Css from "bs-css-emotion/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as StyleVars from "../../toolkit/StyleVars.bs.js";
import * as CssPrelude from "../../toolkit/CssPrelude.bs.js";

var checkbox = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.paddingLeft(StyleVars.Gap.md),
        /* :: */[
          Css.cursor(/* pointer */-786317123),
          /* :: */[
            Css.userSelect(/* none */-922086728),
            /* [] */0
          ]
        ]
      ]
    ]);

var checkboxInput = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.opacity(0),
        /* :: */[
          Css.cursor(/* pointer */-786317123),
          /* :: */[
            Css.height(/* zero */-789508312),
            /* :: */[
              Css.width(/* zero */-789508312),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var checkmarkChecked = Curry._1(Css.style, /* [] */0);

var checkmark = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.minHeight(StyleVars.Size.px26),
        /* :: */[
          Css.minWidth(StyleVars.Size.px26),
          /* :: */[
            Css.height(StyleVars.Size.px26),
            /* :: */[
              Css.width(StyleVars.Size.px26),
              /* :: */[
                Css.backgroundColor(StyleVars.Color.beige),
                /* :: */[
                  Css.hover(/* :: */[
                        Css.backgroundColor(StyleVars.Color.darkBeige),
                        /* [] */0
                      ]),
                  /* :: */[
                    CssPrelude.selectSelf(checkmarkChecked)(/* :: */[
                          Css.backgroundColor(StyleVars.Color.blue),
                          /* :: */[
                            Css.hover(/* :: */[
                                  Css.backgroundColor(StyleVars.Color.black),
                                  /* [] */0
                                ]),
                            /* :: */[
                              Css.after(/* :: */[
                                    Css.contentRule(/* `text */[
                                          -856044371,
                                          ""
                                        ]),
                                    /* :: */[
                                      Css.position(/* absolute */-1013592457),
                                      /* :: */[
                                        Css.left(/* `px */[
                                              25096,
                                              10
                                            ]),
                                        /* :: */[
                                          Css.top(/* `px */[
                                                25096,
                                                5
                                              ]),
                                          /* :: */[
                                            Css.width(/* `px */[
                                                  25096,
                                                  5
                                                ]),
                                            /* :: */[
                                              Css.height(/* `px */[
                                                    25096,
                                                    10
                                                  ]),
                                              /* :: */[
                                                Css.unsafe("border", "solid white"),
                                                /* :: */[
                                                  Css.unsafe("border-width", "0 3px 3px 0"),
                                                  /* :: */[
                                                    Css.transform(/* `rotate */[
                                                          -887077285,
                                                          /* `deg */[
                                                            4995526,
                                                            45.0
                                                          ]
                                                        ]),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]),
                              /* [] */0
                            ]
                          ]
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

export {
  checkbox ,
  checkboxInput ,
  checkmarkChecked ,
  checkmark ,
  
}
/* checkbox Not a pure module */
